<template>
    <div class="meal-kit-page">
        <Slider class="slider" v-if="currentMealKit.pictures.length">
            <SwiperSlide v-for="image in currentMealKit.pictures" :key="image.id">
                <SliderCard
                    class="slider-card"
                    :image="image"
                    :discount-type="currentMealKit.discount?.type"
                    :discount-amount="currentMealKit.discount?.amount"
                    :is-vegetarian="currentMealKit.isVegetarian"
                />
            </SwiperSlide>
        </Slider>
        <Slider class="slider" v-else>
            <SliderCard
                class="slider-card"
                :discount-type="currentMealKit.discount?.type"
                :discount-amount="currentMealKit.discount?.amount"
                :is-vegetarian="currentMealKit.isVegetarian"
            />
        </Slider>
        <div class="header-page__wrapper">
            <TitleSection
                v-if="currentMealKit.name"
                class="title-section"
                :name="$filters.getTranslationByKey(currentMealKit.name)"
                :price="mealKitPrice"
                :price-with-discount="getPriceWithDiscount(mealKitPrice, discountAmount, discountType)"
            />
            <DescriptionSection
                class="mb-25"
                :description="$filters.getTranslationByKey(currentMealKit.description)"
                :meals-amount="currentMealKit.mealsAmount"
                :meals="currentMealKit.meals"
                :with-combinations="currentMealKit.combinations?.length > 1"
                @open-cart="openCart(currentMealKit)"
            />
        </div>
        <WeeksMenuSection
            :calories="currentMealKit.calories"
            :unique-menus="currentMealKit?.uniqueMenus"
            :is-unique="currentMealKit.isUnique"
            :combinations="currentMealKit.combinations"
        />
        <MacronutrientChart v-if="!currentMealKit.isUnique" />
    </div>
</template>

<script>
    import { SwiperSlide } from 'swiper/vue/swiper-slide';
    import Slider from '@/components/Common/Slider/Slider.vue';
    import SliderCard from '@/components/MealKits/SliderCard.vue';
    import TitleSection from '@/components/MealKits/TitleSection.vue';
    import DescriptionSection from '@/components/MealKits/DescriptionSection.vue';
    import WeeksMenuSection from '@/components/MealKits/WeeksMenuSection.vue';
    import MacronutrientChart from '@/components/MealKits/MacronutrientChart.vue';
    import { getPriceWithDiscount } from '@/helpers/CartHelper';
    import { cartMixin } from '@/utils/mixins';
    import { mapGetters } from 'vuex';

    export default {
        name: 'MealKit',
        components: {
            SwiperSlide,
            Slider,
            SliderCard,
            TitleSection,
            DescriptionSection,
            WeeksMenuSection,
            MacronutrientChart,
        },
        mixins: [cartMixin],
        inject: ['provideApp'],
        data() {
            return {
                getPriceWithDiscount,
            };
        },
        computed: {
            ...mapGetters('mealKit', ['currentMealKit']),

            mealKitPrice() {
                return this.currentMealKit.isUnique
                    ? this.currentMealKit.price
                    : this.currentMealKit.calories[0].prices[0].price;
            },

            discountAmount() {
                return this.currentMealKit.discount?.amount;
            },

            discountType() {
                return this.currentMealKit.discount?.type;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .meal-kit-page {
        padding-top: 250px;
        padding-bottom: 25px;

        .selection-wrapper {
            margin-top: 10px;
            margin-bottom: 20px;

            .btn {
                margin-bottom: 10px;
            }

            @include media($lg) {
                display: none;
            }
        }

        .header-page__wrapper {
            .title-section {
                margin-bottom: 10px;
            }
        }
    }

    // slider
    .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    :deep(.slider) {
        padding-bottom: 25px;
    }

    :deep(.slider .swiper-pagination-bullet) {
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        opacity: 1;
        background: $grey-form;
    }
    :deep(.slider .swiper-pagination-bullet-active) {
        background: $primary;
    }

    :deep(.swiper-pagination) {
        bottom: 0px;
    }

    @include media($lg) {
        .meal-kit-page {
            @include row-align-stretch-justify-between;
            flex-wrap: wrap;

            padding-top: 50px;
        }

        .slider {
            width: 48%;
            position: relative;
            border-radius: $br-15;
            margin-bottom: 50px;

            .slider-card {
                border-radius: $br-15;

                height: 500px;
                padding: 10px;
            }
        }

        .header-page__wrapper {
            width: 48%;

            .title-section {
                margin-bottom: 25px;
            }
        }
    }
</style>
