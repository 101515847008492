<template>
    <div class="weeks-menu-section">
        <h3>{{ $t('menus.weeksMenu') }}</h3>
        <div class="filter__wrapper">
            <div v-if="combinations.length > 1 && !isUnique" class="radio__wrapper">
                <h5>{{ $t('mealKits.chooseMealCombination') }}:</h5>
                <CRRadio
                    v-for="combination in combinations"
                    :key="combination.id"
                    :value="combination.id"
                    v-model.number="selectedCombinationId"
                    class="radio-btn"
                >
                    {{ $filters.getTranslationByKey(combination.name) }}
                </CRRadio>
            </div>
            <MenuButtons
                v-if="!isUnique"
                class="menu-btn__wrapper"
                @selectMenuType="handelSelectMenuType"
                :menu-type="menuType"
            />
            <div class="toggle__wrapper">
                <CRToggle v-model:checked="isAllergensShown" class="toggle-btn">
                    {{ $t('buttons.showAllergens') }}
                </CRToggle>
                <CRToggle v-model:checked="isCaloriesShown" :subtitle="$t('common.andNutrients')">
                    {{ $t('buttons.showCalorieNorm') }}
                </CRToggle>
            </div>
            <div class="radio__wrapper mt-10" v-if="!isUnique && calories">
                <h5>{{ $t('mealKits.chooseCalorieSizeLabel') }}</h5>
                <CRRadio
                    v-for="calorie in calories"
                    :key="calorie.id"
                    :value="calorie"
                    v-model="selectedCalorie"
                    :active="selectedCalorie?.id === calorie.id"
                    class="radio-btn"
                >
                    {{ calorie.amount }}
                </CRRadio>
            </div>
            <div v-if="!isUnique" class="week-days-btn__wrapper">
                <CRButton
                    class="btn"
                    v-for="button in weekDaysButtonsData"
                    :key="button.value"
                    :active="selectedWeekDays.includes(button.value)"
                    @click="handelToggleWeekDay(button.value)"
                    pattern="section"
                >
                    {{ button.title }}
                </CRButton>
            </div>
        </div>
        <div class="menu-section" v-if="currentWeekGroupedMenu && selectedCombination">
            <div
                class="menu-section__inner"
                :class="{ 'd-none': !selectedWeekDays.includes(menus[0]) }"
                v-for="(menus, index) in menusList"
                :key="index"
            >
                <h3 class="mb-25 capitalize" v-if="provideApp.$screenInnerWidth >= 1024">
                    {{ $t(`weekDays.${menus[0]}`) }}
                </h3>
                <div v-for="(menu, index) in menus[1]" :key="index">
                    <CustomerDishCard
                        class="mb-20"
                        v-if="selectedCombination.combination.includes(menu.mealType)"
                        :menu="menu"
                        :selected-calorie="selectedCalorie"
                        :menu-name="$t(`common.${menu.mealType}`)"
                        :is-allergens-shown="isAllergensShown"
                        :is-calories-shown="isCaloriesShown"
                        :is-evaluate-dish-disabled="evaluateDishSetting?.isDisabled"
                        @openRatingModal="openRatingModal"
                    />
                </div>
            </div>
        </div>
        <div class="menu-section unique-menu-section">
            <CustomerDishCard
                class="mb-20"
                v-for="menu in uniqueMenusData"
                :key="menu.id"
                :menu="menu"
                :menu-name="$filters.getTranslationByKey(menu.name)"
                :is-allergens-shown="isAllergensShown"
                :is-calories-shown="isCaloriesShown"
                :is-evaluate-dish-disabled="evaluateDishSetting?.isDisabled"
                @openRatingModal="openRatingModal"
            />
        </div>
        <RateDishModal
            v-if="isRatingModalOpened"
            @close="isRatingModalOpened = false"
            :dish-id="editedMenu.dish.id"
            @updateDishRating="updateDishRating"
        />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import MenuButtons from '@/components/Common/Buttons/MenuButtons.vue';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CustomerDishCard from '@/components/MealKits/CustomerDishCard.vue';
    import RateDishModal from '@/components/Modals/RateDishModal';
    import { weeksMenuMixin } from '@/utils/mixins';

    export default {
        name: 'WeeksMenuSection',
        components: { CRRadio, CRButton, CRToggle, CustomerDishCard, RateDishModal, MenuButtons },
        mixins: [weeksMenuMixin],
        props: {
            combinations: {
                type: Array,
                default: () => [],
            },
            uniqueMenus: {
                type: Array,
                default: () => [],
            },
            isUnique: {
                type: Boolean,
                default: false,
            },
            calories: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                uniqueMenusData: this.uniqueMenus,
                selectedCalorie: null,
            };
        },

        mounted() {
            this.selectedCalorie = this.calories[0] ?? null;
        },
    };
</script>

<style lang="scss" scoped>
    .weeks-menu-section {
        width: 100%;

        h3 {
            margin-bottom: 20px;
        }

        @include media($lg) {
            h3 {
                font-size: $font-24;
            }
        }
    }

    // filter__wrapper
    .filter__wrapper {
        margin-bottom: 12px;

        .radio__wrapper {
            h5 {
                margin-bottom: 10px;
            }

            .radio-btn {
                margin-bottom: 5px;
            }
        }

        .menu-btn__wrapper {
            margin-top: 15px;
        }

        .toggle__wrapper {
            margin-top: 15px;
            margin-bottom: 20px;

            .toggle-btn {
                margin-bottom: 10px;
            }
        }

        .week-days-btn__wrapper {
            @include row-align-center;

            width: 100%;
            margin-top: 35px;

            .btn {
                margin-right: 10px;
                cursor: pointer;
                pointer-events: all;
            }
        }
    }

    @include media($md) {
        .filter__wrapper {
            @include column-align-start;
            flex-wrap: wrap;

            margin-bottom: 30px;

            .radio__wrapper {
                @include row-align-center;
                flex-wrap: wrap;
                width: 100%;

                h5 {
                    margin-right: 10px;
                }

                .radio-btn {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }

            .menu-btn__wrapper {
                margin-bottom: 20px;
                margin-right: 40px;
            }

            .toggle__wrapper {
                @include row-align-start;

                .toggle-btn {
                    margin-bottom: 0;
                    margin-right: 20px;
                }
            }
        }
    }

    // menu-section
    @include media($md) {
        .menu-section {
            @include row-align-stretch-justify-between;
            width: 100%;

            .menu-section__inner {
                width: 49%;
            }
        }
    }

    // unique-menu-section
    .unique-menu-section {
        margin-top: 40px;
    }

    @include media($md) {
        .unique-menu-section {
            flex-wrap: wrap;
            width: 100%;

            .card {
                width: 49%;
            }
        }
    }
</style>
