<template>
    <div class="title-section">
        <h2>{{ name }}</h2>
        <PriceBlock class="price-block" :price="price" :price-with-discount="priceWithDiscount" />
    </div>
</template>

<script>
    import PriceBlock from '@/components/MealKits/PriceBlock.vue';

    export default {
        name: 'TitleSection',
        components: { PriceBlock },

        props: {
            name: {
                type: String,
                default: '',
            },
            price: {
                type: Number,
                default: 0,
            },
            priceWithDiscount: {
                type: [Number, String],
                default: 0,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .title-section {
        @include row-align-start-justify-between;

        .price-block {
            min-width: 130px;
            margin-left: 10px;
        }
    }

    @include media($lg) {
        .title-section {
            @include row-align-start-justify-between;
            flex-wrap: wrap;

            h2 {
                width: 100%;
                font-size: $font-34;
                margin-bottom: 35px;
            }

            .price-block {
                @include column-align-start;
                margin-left: 0;

                &:deep(.price__wrapper) {
                    @include row-align-end;

                    h2 {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
</style>
